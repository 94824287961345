@media (max-width: 767px) and (min-width: 568px) {
  .user_profileimg {
    margin: -55px 0px 0px 5px;
  }
  .user_profileimg .profile_img {
    width: 100px;
    height: 100px !important;
  }
  .user_profileimg .ant-image-mask {
    width: 100px;
    height: 100px !important;
  }
  .user_profileimg h3 {
    margin: -80px 0px 0px 120px;
    font-size: 17px;
  }
}
@media (max-width: 567px) and (min-width: 325px) {
  .user_profileimg {
    margin: -35px 0px 0px 5px;
  }
  .user_profileimg .profile_img {
    width: 70px;
    height: 70px !important;
  }
  .user_profileimg .ant-image-mask {
    width: 70px;
    height: 70px !important;
  }
  .user_profileimg h3 {
    margin: -105px 0px 0px 80px;
    font-size: 14px;
  }
}

@media (max-width: 775px) and (min-width: 320px) {
  .header4 {
    display: flex;
    flex-direction: column;
  }
  .events-button {
    display: flex;
    flex-direction: column;
  }
  .header4 button {
    width: 100%;
  }
  .add-event {
    margin-top: 7px;
  }
}
