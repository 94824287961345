@import url("./homepage.css");
@import url("./login.css");
@import url("./form.css");
@import url("./dashboard.css");
@import url("./custom.css");
@import url("./users.css");
@import url("./media.css");
@import url("./events.css");

* {
  margin: 0;
  padding: 0;
  font-family: "Montserrat", sans-serif;
}
