/* events calender css */
.events {
  margin: 0;
  padding: 0;
  list-style: none;
  text-align: right;
}
.events .ant-badge-status {
  width: 100%;
  overflow: hidden;
  font-size: 12px;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.notes-month {
  font-size: 28px;
  text-align: center;
}
.notes-month section {
  font-size: 28px;
}
.add_event_calendar p {
  padding: 2px 0px;
  font-size: 14px;
  font-weight: 400;
}

.head_events h3 {
  color: #444;
  font-size: 18px;
  font-weight: 600;
  padding: 5px 0px 20px 0px;
}
