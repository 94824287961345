.login-page {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background: #fff;
}
.login-page .ant-form-item-label {
  padding: 0px !important;
  color: #333;
  font-family: "Josefin Sans", sans-serif;
  font-size: 30px;
  font-weight: bold;
}
.login-box .logo-container img {
  height: 60px;
}
.login-box .logo-container {
  display: flex;
  justify-content: center;
  padding-bottom: 30px;
}
.login-box {
  display: flex;
  justify-content: space-between;
  flex-direction: row-reverse;
  width: 22%;
  background-color: white;
  box-shadow: 0 0 40px rgba(0, 0, 0, 0.16);
  overflow: hidden;
  margin: 0 auto;
  border-radius: 12px;
}
#login-form {
  width: 100%;
  padding: 60px;
}
#login-form p {
  margin-bottom: 30px;
}
#login-form p.form-title {
  color: #333;
  font-family: "Josefin Sans", sans-serif;
  font-size: 30px;
  font-weight: bold;
  line-height: 1;
  margin-bottom: 0;
}
#login-form .ant-form-item-label > label.ant-form-item-required::before {
  display: none;
}
.ant-form-item-control-input-content {
  text-align: left;
}
#login-form .ant-input-affix-wrapper {
  padding: 12px 15px;
}
#login-form_email {
  height: 48px;
}
#login-form .ant-btn {
  height: 42px;
  letter-spacing: 1px;
  border-radius: 6px;
}
.login-form-button {
  width: 100%;
  background-color: #001529;
  color: #fff;
  font-weight: 600;
  font-size: 14px;
}
@media screen and (max-width: 1900px) {
  .login-box {
    width: 30%;
  }
}
@media screen and (max-width: 1500px) {
  .login-box {
    width: 40%;
  }
}
@media screen and (max-width: 1023px) {
  .login-box {
    width: 60%;
  }
}
@media screen and (max-width: 700px) {
  .login-box {
    width: 80%;
  }
}
