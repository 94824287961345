/*  */
.cursor-pointer {
  cursor: pointer;
  font-size: 18px;
}
/* .white-color */
.py-4 {
  padding-top: 5px;
  padding-bottom: 5px;
}
.px-4 {
  padding-left: 5px;
  padding-right: 5px;
}
.py-5 {
  padding-bottom: 12px;
}
.flex {
  display: flex;
}
/* rounded */
.rounded-10 {
  border-radius: 10px;
}
.rounded-20 {
  border-radius: 20px;
}
.py-10 {
  padding-top: 10px;
  padding-bottom: 10px;
}
/* editor css */
.editor-height {
  min-height: 150px;
  border: 1px solid #d9d9d9;
  border-radius: 5px;
  padding: 8px;
}
.border-radius-2 {
  border-radius: 2px !important;
}
/*  */
/* placeholder */
.datepicker-container ::placeholder {
  color: #000 !important;
  opacity: 1 !important; /* Firefox */
}
.datepicker-container :-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #000 !important;
  opacity: 1;
}
.datepicker-container ::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #000 !important;
}
/*  */
.csv-button {
  margin-right: 5px;
  background-color: green !important;
}
