/* user details css start here */
.user_profileimg {
  position: relative;
  margin: -80px 0px 0px 10px;
}
.user_profileimg .profile_img {
  border-radius: 100px;
  border: 5px solid #fff;
}
.user_profileimg .ant-image-mask {
  border-radius: 100px;
}
.user_profileimg h3 {
  margin: -50px 0px 0px 170px;
  font-size: 20px;
  font-weight: 600;
  color: #2b2b2b;
}
/* user details css */
.user_profile {
  position: absolute !important;
  bottom: 120px;
  left: 20px;
}
/* contact view */
.view_content {
  padding-top: 20px;
}
.view_content h2 {
  padding-bottom: 20px;
}
.view_content h4 {
  font-weight: 600;
  color: rgba(0, 0, 0, 0.88);
}
.view_content h6 {
  font-weight: 400;
  font-size: 15px;
  color: rgba(0, 0, 0, 0.88);
  padding-bottom: 20px;
}
